/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@import url("//fonts.googleapis.com/css?family=Source+Sans+Pro");
@import url("//use.typekit.net/ifr8dxc.css");
@import "variables";

body.login {
	background: #000 url(../img/bg.jpg) no-repeat center center / cover;
	font-family: $baseFontFamily;
	color: #FFF;
	h1 {
		a {
			width: 200px;
			height: 162px;
			background: url(../img/logo.png) no-repeat center center / contain;
		}
	}
	form {
		box-shadow: none;
		background: none;
	}
	label {
		color: #FFF;
	}
	.message {
		color: #000;
	}
	&:after {
		content: '';
		width: 100%;
		height: 400px;
		display: block;
		background: linear-gradient(#000, transparent);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0.5;
	}
}

#login {
	padding-bottom: 20px;
	background: rgba(#000, 0.9);
	box-shadow: none;
	position: relative;
	z-index: 2;
}

.login #backtoblog,
.login #nav {
	a {
		color: $color1;
		&:hover {
			color: $color1;
			text-decoration: underline;
		}
	}
}

.wp-core-ui {
	.button-primary {
		background: #FFF;
		color: $color3;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-shadow: none;
		text-transform: uppercase;
		font-weight: 700;
		font-family: $secondaryFontFamily;
		transition: all 0.2s ease-in-out;
		&:hover,
		&:active,
		&:focus {
			background: $color2 !important;
			color: #FFF;
			outline: 0;
			box-shadow: none !important;
		}
	}
}
