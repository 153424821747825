/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// GENERAL
// ---------------

$color1:              #AAA;
$color2:              #777;
$color3:              #221f1f;
$color4:              #131111;

// TYPOGRAPHY
// ---------------

$textColor:           #FFF;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "Source Sans Pro", Helvetica, Arial, sans-serif;
$secondaryFontFamily: "brandon-grotesque", $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       #FFF;

$hrColor:             $color1;
